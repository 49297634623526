import React, { useEffect, useRef } from 'react';
import './MediaViewer.css';

const MediaViewer = ({ selectedMedia }) => {
    const videoRef = useRef(null);

    // Update video source and play when media changes
    useEffect(() => {
        if (selectedMedia?.type === 'video' && videoRef.current) {
            // First pause the current video
            videoRef.current.pause();
            // Update the source
            videoRef.current.src = selectedMedia.url;
            // Load and play the new video
            videoRef.current.load();
            videoRef.current.play().catch(err => {
                console.log('Auto-play failed:', err);
            });
        }
    }, [selectedMedia]);

    return (
        <div className="media-viewer">
            {selectedMedia ? (
                selectedMedia.type === 'video' ? (
                    <video 
                        ref={videoRef}
                        height="100%" 
                        controls
                        playsInline
                    >
                        <source src={selectedMedia.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={selectedMedia.url} alt={selectedMedia.name} />
                )
            ) : (
                <div className="placeholder">Select a media item to view</div>
            )}
        </div>
    );
};

export default MediaViewer;

