import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MediaGallery.css';
import { FaPlay } from 'react-icons/fa';

const MediaGallery = ({ mediaItems, onSelect, onDurationChange }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isShiftPressed, setIsShiftPressed] = useState(false);

    // Track shift key state
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Shift') setIsShiftPressed(true);
        };
        const handleKeyUp = (e) => {
            if (e.key === 'Shift') setIsShiftPressed(false);
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const handleItemClick = (item, e) => {
        if (isShiftPressed) {
            setSelectedItems(prev => {
                const isSelected = prev.some(i => i.id === item.id);
                if (isSelected) {
                    return prev.filter(i => i.id !== item.id);
                } else {
                    return [...prev, item];
                }
            });
        } else {
            setSelectedItems([item]);
            if (onSelect) onSelect(item);
        }
    };

    const handleDragStart = (e, item) => {
        e.stopPropagation();
        
        // Check if we're dragging a selected item in a multi-select
        const itemsToDrag = selectedItems.length > 1 && selectedItems.includes(item)
            ? selectedItems
            : [item];
        
        console.log('Starting drag with items:', itemsToDrag);
        e.dataTransfer.setData('application/json', JSON.stringify(itemsToDrag));
        e.dataTransfer.effectAllowed = 'move';
    };

    useEffect(() => {
        const calculateTotalDuration = async () => {
            let total = 0;
            for (const item of selectedItems) {
                if (item.type === 'video') {
                    try {
                        const response = await axios.post('/api/video-duration', {
                            filePath: item.path || item.url
                        });
                        total += response.data.duration;
                    } catch (error) {
                        console.error('Error getting video duration:', error);
                    }
                }
            }
            if (onDurationChange) {
                onDurationChange(total);
            }
        };

        calculateTotalDuration();
    }, [selectedItems, onDurationChange]);

    return (
        <div className="media-gallery-container">
            <div className="media-gallery">
                {mediaItems.map((item, index) => (
                    <div 
                        key={index} 
                        className={`media-item ${selectedItems.some(i => i.id === item.id) ? 'selected' : ''}`}
                        draggable="true"
                        onDragStart={(e) => handleDragStart(e, item)}
                        onClick={(e) => handleItemClick(item, e)}
                    >
                        <div className="thumbnail-container">
                            {item.type === 'video' ? (
                                <>
                                    <video 
                                        src={item.url}
                                        preload="metadata"
                                        className="media-thumbnail"
                                    >
                                        <track kind="captions" />
                                    </video>
                                    <div className="video-icon">
                                        <FaPlay />
                                    </div>
                                </>
                            ) : (
                                <img 
                                    src={item.url}
                                    alt={item.name}
                                    className="media-thumbnail"
                                />
                            )}
                        </div>
                        <p className="media-filename">{item.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MediaGallery;
