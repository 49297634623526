import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFolder, FaPlus, FaArrowLeft, FaArrowRight, FaPlay, FaFile } from 'react-icons/fa';
import '../styles/FolderManagement.css';

const FolderManagement = ({ currentPath, onFileMove, onRefresh, onMediaSelect }) => {
    const [newFolderName, setNewFolderName] = useState('');
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [isDragOver, setIsDragOver] = useState(null);
    const [rtpPath, setRtpPath] = useState('');
    const [navigationHistory, setNavigationHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [selectionBox, setSelectionBox] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isSelecting, setIsSelecting] = useState(false);
    const [startPoint, setStartPoint] = useState(null);
    const [isDragOverFolder, setIsDragOverFolder] = useState(null);

    useEffect(() => {
        if (currentPath) {
            const clientName = currentPath.split('/')[0];
            const newRtpPath = `${clientName}/RTP_${clientName}`;
            setRtpPath(newRtpPath);
            setNavigationHistory([newRtpPath]);
            setHistoryIndex(0);
            fetchFolders(newRtpPath);
        }
    }, [currentPath]);

    const fetchFolders = async (path) => {
        try {
            console.log('Fetching folders for path:', path); // Debug log
            
            if (!path) {
                console.error('Empty path provided to fetchFolders');
                return;
            }

            const [foldersResponse, filesResponse] = await Promise.all([
                axios.get(`/api/directories?path=${encodeURIComponent(path)}`),
                axios.get(`/api/files?path=${encodeURIComponent(path)}`)
            ]);
            
            // Separate date folders from other folders
            const dateFolders = [];
            const otherFolders = [];
            
            foldersResponse.data.forEach(folder => {
                // Check if folder matches date pattern (MM-DD-HH-MM-XXX)
                if (/^\d{2}-\d{2}-\d{2}-\d{2}-\d{3}$/.test(folder)) {
                    dateFolders.push(folder);
                } else {
                    otherFolders.push(folder);
                }
            });
            
            // Sort date folders in descending order
            dateFolders.sort((a, b) => b.localeCompare(a));
            
            // Combine the arrays with date folders first, then other folders
            setFolders([...dateFolders, ...otherFolders]);
            setFiles(filesResponse.data);
        } catch (error) {
            console.error('Failed to fetch content:', error);
        }
    };

    const handleFolderClick = (folder) => {
        // Get the current RTP path and append the new folder
        const newPath = navigationHistory[historyIndex] 
            ? `${navigationHistory[historyIndex]}/${folder}`
            : `${rtpPath}/${folder}`;
        
        console.log('New path:', newPath); // Debug log
        
        // Update navigation history
        const newHistory = [...navigationHistory.slice(0, historyIndex + 1), newPath];
        setNavigationHistory(newHistory);
        setHistoryIndex(historyIndex + 1);
        setRtpPath(newPath);
        
        fetchFolders(newPath);
    };

    const handleBack = () => {
        if (historyIndex > 0) {
            setHistoryIndex(historyIndex - 1);
            const previousPath = navigationHistory[historyIndex - 1];
            setRtpPath(previousPath);
            fetchFolders(previousPath);
        }
    };

    const handleForward = () => {
        if (historyIndex < navigationHistory.length - 1) {
            setHistoryIndex(historyIndex + 1);
            const nextPath = navigationHistory[historyIndex + 1];
            setRtpPath(nextPath);
            fetchFolders(nextPath);
        }
    };

    const handleCreateFolder = async () => {
        if (!newFolderName.trim()) return;
        
        try {
            await axios.post('/api/create-folder', {
                path: rtpPath,
                folderName: newFolderName
            });
            setNewFolderName('');
            fetchFolders(rtpPath);
        } catch (error) {
            console.error('Failed to create folder:', error);
        }
    };

    const handleCreateDay = async () => {
        try {
            const response = await axios.post('/api/create-day', {
                path: rtpPath,
                prefixes: ['M', 'A', 'E']
            });
            
            if (response.data.message === "Day folders created successfully") {
                fetchFolders(rtpPath);
            }
        } catch (error) {
            console.error('Failed to create day folders:', error);
        }
    };

    const handleDragOver = (e, folder) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(folder);
        e.dataTransfer.dropEffect = 'move';
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(null);
    };

    const handleDrop = async (e, targetFolder) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOver(null);
        
        try {
            const data = e.dataTransfer.getData('application/json');
            const dragData = JSON.parse(data);
            const itemsToMove = dragData.type === 'media-items' ? dragData.items : [dragData];
            
            console.log('Moving items:', itemsToMove);

            for (const item of itemsToMove) {
                try {
                    await axios.post('/api/move-file', {
                        sourceFilePath: item.path,
                        targetFolder: `${rtpPath}/${targetFolder}`,
                        fileName: item.name
                    });
                } catch (moveError) {
                    console.error(`Failed to move ${item.name}:`, moveError);
                }
            }
            
            await fetchFolders(rtpPath);
            if (onFileMove) onFileMove();
        } catch (error) {
            console.error('Drop error:', error);
        }
    };

    const handleContentAreaDrop = async (e) => {
        e.preventDefault();
        setIsDragOver(null);
        
        try {
            const dragData = JSON.parse(e.dataTransfer.getData('application/json'));
            if (!dragData || !dragData.path) return;
            
            const fileName = dragData.path.split('/').pop();
            const fileNameWithoutExt = fileName.split('.')[0];
            
            const response = await axios.post('/api/move-file', {
                sourceFilePath: dragData.path.replace('/media/', ''),
                targetFolder: rtpPath,
                fileName: fileName,
                thumbnailName: dragData.type === 'video' ? `${fileNameWithoutExt}_thumb.jpg` : null
            });

            if (response.data.message === "File moved successfully") {
                // Refresh both folders
                await fetchFolders(rtpPath);
                if (onFileMove) {
                    await onFileMove(dragData.path, rtpPath);
                }
                // Force a refresh of the file list
                await fetchFiles(rtpPath);
            }
        } catch (error) {
            console.error('Failed to move file:', error);
        }
    };

    const fetchFiles = async (path = rtpPath) => {
        try {
            const response = await axios.get(`/api/files?path=${encodeURIComponent(path)}`);
            setFiles(response.data.map(file => ({
                ...file,
                url: `/media/${rtpPath}/${file.name}`
            })));
        } catch (error) {
            console.error('Failed to fetch files:', error);
        }
    };

    const handleFileClick = (file, e) => {
        const fileWithPath = {
            ...file,
            url: `/media/${rtpPath}/${file.name}`,
            type: file.type,
            name: file.name,
            path: `${rtpPath}/${file.name}`
        };

        if (!e) {
            setSelectedFiles([file]);
            if (onMediaSelect) onMediaSelect(fileWithPath);
            return;
        }

        if (e.shiftKey) {
            setSelectedFiles(prev => [...prev, file]);
        } else {
            setSelectedFiles([file]);
            if (onMediaSelect) onMediaSelect(fileWithPath);
        }
    };

    const handleFolderDragOver = (e, folderName) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOverFolder(folderName);
    };

    const handleFolderDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOverFolder(null);
    };

    const handleFolderDrop = async (e, folderName) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragOverFolder(null);
        
        try {
            const data = JSON.parse(e.dataTransfer.getData('application/json'));
            // Handle both array formats (direct array or wrapped in type property)
            const items = Array.isArray(data) ? data : 
                         data.type === 'media-items' ? data.items : [data];
            
            console.log('Moving items:', items, 'into folder:', folderName);
            
            for (const item of items) {
                try {
                    // Remove /media/ prefix if it exists
                    const sourcePath = item.path.replace(/^\/media\//, '');
                    
                    await axios.post('/api/move-file', {
                        sourceFilePath: sourcePath,
                        targetFolder: `${rtpPath}/${folderName}`,
                        fileName: item.name
                    });
                } catch (error) {
                    console.error(`Failed to move ${item.name}:`, error);
                }
            }
            
            // Refresh the view after all moves are complete
            await fetchFolders(rtpPath);
            if (onFileMove) onFileMove();
            
        } catch (error) {
            console.error('Drop error:', error);
        }
    };

    return (
        <div className="folder-management">
            {/* Left Sidebar */}
            <div className="folder-sidebar">
                <div className="folder-navigation">
                    <button 
                        onClick={handleBack} 
                        disabled={historyIndex <= 0}
                        className="nav-button"
                    >
                        <FaArrowLeft />
                    </button>
                    <button 
                        onClick={handleForward}
                        disabled={historyIndex >= navigationHistory.length - 1}
                        className="nav-button"
                    >
                        <FaArrowRight />
                    </button>
                    <span className="current-rtp-path">{rtpPath}</span>
                </div>
                
                <div className="folder-controls">
                    <input
                        type="text"
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        placeholder="New folder name"
                    />
                    <button onClick={handleCreateFolder}>
                        <FaPlus /> Create Folder
                    </button>
                    <button onClick={handleCreateDay} className="create-day-btn">
                        <FaPlus /> Create Day
                    </button>
                </div>

                <div className="folder-list">
                    {folders.map((folder) => (
                        <div
                            key={folder}
                            className={`folder ${isDragOverFolder === folder ? 'drag-over' : ''}`}
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsDragOverFolder(folder);
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsDragOverFolder(null);
                            }}
                            onDrop={(e) => handleFolderDrop(e, folder)}
                            onClick={() => handleFolderClick(folder)}
                        >
                            <FaFolder className="folder-icon" />
                            <span className="folder-name">{folder}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Main Content Area */}
            <div 
                className={`content-area ${isDragOver === 'content-area' ? 'drag-over' : ''}`}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.dataTransfer.types.includes('application/json')) {
                        setIsDragOver('content-area');
                    }
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsDragOver(null);
                }}
                onDrop={handleContentAreaDrop}
                onMouseDown={(e) => {
                    // Only start selection if left mouse button is clicked
                    if (e.button === 0) {
                        const rect = e.currentTarget.getBoundingClientRect();
                        const startX = e.clientX - rect.left;
                        const startY = e.clientY - rect.top;
                        setStartPoint({ x: startX, y: startY });
                        setIsSelecting(true);
                        setSelectionBox({
                            left: startX,
                            top: startY,
                            width: 0,
                            height: 0
                        });
                    }
                }}
                onMouseMove={(e) => {
                    if (isSelecting && startPoint) {
                        const rect = e.currentTarget.getBoundingClientRect();
                        const currentX = e.clientX - rect.left;
                        const currentY = e.clientY - rect.top;
                        
                        const left = Math.min(startPoint.x, currentX);
                        const top = Math.min(startPoint.y, currentY);
                        const width = Math.abs(currentX - startPoint.x);
                        const height = Math.abs(currentY - startPoint.y);
                        
                        setSelectionBox({ left, top, width, height });
                        
                        // Check which files are within the selection box
                        const selected = files.filter(file => {
                            const fileEl = document.getElementById(`file-${file.id}`);
                            if (fileEl) {
                                const fileRect = fileEl.getBoundingClientRect();
                                const fileLeft = fileRect.left - rect.left;
                                const fileTop = fileRect.top - rect.top;
                                
                                return (
                                    fileLeft < left + width &&
                                    fileLeft + fileRect.width > left &&
                                    fileTop < top + height &&
                                    fileTop + fileRect.height > top
                                );
                            }
                            return false;
                        });
                        
                        setSelectedFiles(selected);
                    }
                }}
                onMouseUp={() => {
                    setIsSelecting(false);
                    setSelectionBox(null);
                    setStartPoint(null);
                }}
                onMouseLeave={() => {
                    setIsSelecting(false);
                    setSelectionBox(null);
                    setStartPoint(null);
                }}
            >
                {/* Render selection box */}
                {selectionBox && (
                    <div 
                        className="selection-box"
                        style={{
                            left: selectionBox.left,
                            top: selectionBox.top,
                            width: selectionBox.width,
                            height: selectionBox.height
                        }}
                    />
                )}
                
                {/* Update file rendering to include selection state */}
                <div className="content-list">
                    {files.map((file) => (
                        <div
                            id={`file-${file.id}`}
                            key={file.id}
                            className={`file has-thumbnail ${
                                isDragOver === file.id ? 'drag-over' : ''
                            } ${file.type} ${
                                selectedFiles.includes(file) ? 'selected' : ''
                            }`}
                            onClick={(e) => handleFileClick(file, e)}
                        >
                            <div className="thumbnail-container">
                                {file.type === 'video' ? (
                                    <>
                                        <video 
                                            src={`/media/${rtpPath}/${file.name}`}
                                            preload="metadata"
                                            className="thumbnail"
                                        >
                                            <track kind="captions" />
                                        </video>
                                        <div className="video-icon">
                                            <FaPlay />
                                        </div>
                                    </>
                                ) : (
                                    <img 
                                        src={`/media/${rtpPath}/${file.name}`}
                                        alt={file.name}
                                        className="thumbnail"
                                    />
                                )}
                            </div>
                            <div className="file-info">
                                <span className="file-name">{file.name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FolderManagement;
