import React from 'react';
import './ClientInstructions.css';

// This object contains client-specific instructions
const CLIENT_INSTRUCTIONS = {
  'alanac': `
    • All videos must be under 60 seconds
    • Add captions to every video
    • Compress before uploading
    • Use specific hashtags: #JohnnyH #Content #Lifestyle
  `,
  'default': `
    • Select a client to view specific instructions
    • General guidelines:
      - Videos should be properly compressed
      - Check audio quality
      - Ensure proper formatting
  `
  // Add more clients as needed
};

const ClientInstructions = ({ clientName }) => {
  const instructions = CLIENT_INSTRUCTIONS[clientName] || CLIENT_INSTRUCTIONS.default;
  
  return (
    <div className="client-instructions">
      <h3>Client Instructions</h3>
      <pre>{instructions}</pre>
    </div>
  );
};

export default ClientInstructions; 