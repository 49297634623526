import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';
import { FaPlay } from 'react-icons/fa';
import MediaViewer from './components/MediaViewer';
import FolderManagement from './components/FolderManagement';
import ClientInstructions from './components/ClientInstructions';
import MediaGallery from './components/MediaGallery';

// Update the base URL constants
const API_BASE_URL = 'https://edit.snapped.cc';
const MEDIA_BASE_URL = '/media';  // Changed to be path-only

axios.defaults.baseURL = API_BASE_URL;

function App() {
  const [directories, setDirectories] = useState([]);
  const [currentPath, setCurrentPath] = useState('');
  const [mediaItems, setMediaItems] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editProgress, setEditProgress] = useState(0);
  const [startTrim, setStartTrim] = useState(0);
  const [endTrim, setEndTrim] = useState(0);
  const [showTrimPopup, setShowTrimPopup] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [showCutPopup, setShowCutPopup] = useState(false);
  const [chunkDuration, setChunkDuration] = useState(60); // Default to 60 seconds
  const [searchQuery, setSearchQuery] = useState('');
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [directorySearch, setDirectorySearch] = useState('');
  const directorySearchRef = useRef(null);
  const mediaExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.mp3', '.wav', '.flac', '.jpg', '.jpeg', '.png', '.gif'];
  const [selectedMediaItems, setSelectedMediaItems] = useState([]);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const clientSearchRef = useRef(null);
  const [clientSearch, setClientSearch] = useState('');
  const [isSelectManyMode, setIsSelectManyMode] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  const [totalDuration, setTotalDuration] = useState(0);

  const formatPath = (path) => {
    const parts = path.split('/').filter(Boolean);
    if (parts.length <= 2) return path;
    if (parts[1] && parts[1].startsWith('CONTENT_')) {
      return path;
    }
    return `${parts[0]}/.../${parts[parts.length - 1]}`;
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (currentPath) {
      fetchDirectories(currentPath);
      fetchMediaItems();
    }
  }, [currentPath]);

  const fetchDirectories = async (path = '') => {
    try {
      const response = await axios.get(`/api/directories?path=${encodeURIComponent(path)}`);
      setDirectories(response.data);
      setCurrentPath(path);
    } catch (error) {
      console.error('Failed to fetch directories:', error);
      setError('Failed to fetch directories');
    }
  };

  const fetchMediaItems = async () => {
    try {
      const response = await axios.get(`/api/files?path=${encodeURIComponent(currentPath)}`);
      console.log('API Response:', response.data);

      const updatedMediaItems = response.data.map(item => ({
        ...item,
        url: `${MEDIA_BASE_URL}/${item.path}`.replace(/\/+/g, '/')
      }));
      setMediaItems(updatedMediaItems);
      setError(null);

      if (selectedMedia && !updatedMediaItems.some(item => item.path === selectedMedia.path)) {
        setSelectedMedia(null);
      }
      
      setSelectedMediaItems(prev => prev.filter(item => 
        updatedMediaItems.some(newItem => newItem.path === item.path)
      ));

    } catch (error) {
      console.error('Failed to fetch media items:', error);
      setError('Failed to fetch media items: ' + (error.response?.data?.error || error.message));
      setMediaItems([]);
    }
  };

  const handleDirectoryClick = (dir) => {
    const newPath = `${currentPath}/${dir}`;
    fetchDirectories(newPath);
  };

  const handleBackClick = () => {
    const newPath = currentPath.split('/').slice(0, -1).join('/');
    fetchDirectories(newPath);
  };

  const handleEditAction = async (action) => {
    // First, determine which items to process
    let itemsToProcess = [];
    
    if (isSelectManyMode) {
      itemsToProcess = selectedMediaItems;
    } else if (selectedMedia) {
      itemsToProcess = [selectedMedia];
    }

    // Don't proceed if no items selected
    if (itemsToProcess.length === 0) {
      setError('No media selected');
      return;
    }

    // Special handling for trim action
    if (action === 'trim') {
      console.log('Trim action triggered'); // Debug log
      if (selectedMedia?.type === 'video') {
        try {
          // First get the video duration
          const response = await axios.post('/api/video-duration', {
            filePath: selectedMedia.path
          });
          
          // Set the duration and default end time
          setVideoDuration(response.data.duration);
          setStartTrim(0);
          setEndTrim(response.data.duration);
          
          // Show the popup
          setShowTrimPopup(true);
        } catch (error) {
          console.error('Failed to get video duration:', error);
          setError('Failed to get video duration: ' + error.message);
        }
        return;
      } else {
        setError('Please select a video to trim');
        return;
      }
    }

    // Special handling for rename action
    if (action === 'rename') {
      if (selectedMedia) {
        setNewFileName(selectedMedia.name);
        setShowRenamePopup(true);
        return;
      } else {
        setError('Please select a file to rename');
        return;
      }
    }

    setIsEditing(true);
    setEditProgress(0);
    setProcessingMessage('Processing...');

    try {
      for (let i = 0; i < itemsToProcess.length; i++) {
        const media = itemsToProcess[i];
        const response = await axios.post(`/api/${action}`, {
          filePath: media.path
        });

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        setEditProgress(((i + 1) / itemsToProcess.length) * 100);
      }

      await fetchMediaItems();
      
    } catch (error) {
      console.error(`Failed to ${action}:`, error);
      setError(`Failed to ${action}: ${error.message}`);
    } finally {
      setTimeout(() => {
        setIsEditing(false);
        setEditProgress(0);
        setProcessingMessage('');
      }, 1000);
    }
  };

  const renderProgressBar = () => {
    if (!isEditing) return null;
    
    return (
      <div className="progress-container">
        <div className="progress-bar">
          <div 
            className="progress-fill" 
            style={{ width: `${editProgress}%` }}
          />
        </div>
        <div className="progress-info">
          <span className="progress-percentage">{editProgress.toFixed(0)}%</span>
          {processingMessage && (
            <span className="progress-message">{processingMessage}</span>
          )}
        </div>
      </div>
    );
  };

  const handleTrimSubmit = async () => {
    setIsEditing(true);
    setEditProgress(0);
    setShowTrimPopup(false);

    try {
      const response = await axios.post('/api/trim', {
        filePath: selectedMedia.path,
        startTime: startTrim,
        endTime: endTrim
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      await fetchMediaItems();
      
      setSelectedMedia({
        ...selectedMedia,
        name: response.data.fileName,
        path: response.data.outputPath,
        url: `${MEDIA_BASE_URL}${response.data.outputPath}`.replace(/\/+/g, '/')
      });

    } catch (error) {
      console.error('Failed to trim:', error);
      setError(`Failed to trim: ${error.response?.data?.error || error.message}`);
    } finally {
      setTimeout(() => {
        setIsEditing(false);
        setEditProgress(0);
      }, 500);
    }
  };

  const TrimPopup = () => (
    <div className="popup-overlay">
      <div className="popup-content trim-popup">
        <h3>Trim Video</h3>
        <p>Video Duration: {formatDuration(videoDuration)}</p>
        <div className="trim-controls">
          <label>
            Start Time (seconds):
            <input 
              type="number" 
              value={startTrim} 
              onChange={(e) => setStartTrim(Math.max(0, Math.min(Number(e.target.value), endTrim)))} 
              min="0"
              max={videoDuration}
              step="0.1"
            />
          </label>
          <label>
            End Time (seconds):
            <input 
              type="number" 
              value={endTrim} 
              onChange={(e) => setEndTrim(Math.max(startTrim, Math.min(Number(e.target.value), videoDuration)))} 
              min={startTrim}
              max={videoDuration}
              step="0.1"
            />
          </label>
        </div>
        <div className="popup-buttons">
          <button onClick={handleTrimSubmit}>Trim</button>
          <button onClick={() => setShowTrimPopup(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );

  const RenamePopup = () => {
    return (
      <div className="popup-overlay">
        <div className="popup-content rename-popup">
          <h3>Rename File</h3>
          <div className="rename-controls">
            <label>
              New File Name:
              <input 
                type="text" 
                value={newFileName} 
                onChange={(e) => setNewFileName(e.target.value)}
                placeholder="Enter new file name"
              />
            </label>
          </div>
          <div className="popup-buttons">
            <button onClick={handleRenameSubmit}>Rename</button>
            <button onClick={() => setShowRenamePopup(false)}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };

  const handleRenameSubmit = async () => {
    try {
      const response = await axios.post('/api/rename', {
        filePath: selectedMedia.path,
        newName: newFileName
      });

      setSelectedMedia({
        ...selectedMedia,
        name: response.data.fileName,
        path: response.data.outputPath,
        url: `${MEDIA_BASE_URL}${response.data.outputPath}`.replace(/\/+/g, '/')
      });
    } catch (error) {
      console.error('Failed to rename:', error);
      setError(`Failed to rename: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleCutSubmit = async () => {
    setIsEditing(true);
    setEditProgress(0);
    setShowCutPopup(false);

    try {
      let requestData = { 
        filePath: selectedMedia.path,
        chunkDuration: chunkDuration
      };

      setEditProgress(10);

      const response = await axios.post('/api/cut', requestData);

      setEditProgress(50);

      console.log(response.data.message);
      
      // Add a delay before refreshing media items
      await new Promise(resolve => setTimeout(resolve, 2000)); // 2 second delay
      
      // Refresh the current directory to show the new chunks folder
      await fetchDirectories(currentPath);
      await fetchMediaItems();
      
      setSelectedMedia(null); // Reset selected media as we now have a new folder
      setEditProgress(100);
    } catch (error) {
      console.error('Failed to cut:', error);
      setError(`Failed to cut: ${error.response?.data?.error || error.message}`);
    } finally {
      setTimeout(() => {
        setIsEditing(false);
        setEditProgress(0);
      }, 500);
    }
  };

  const CutPopup = () => {
    const inputRef = React.useRef(null);

    React.useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const formatDuration = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
      <div className="popup">
        <div className="popup-content">
          <h3>Cut Video into Chunks</h3>
          <p>Total Video Duration: {formatDuration(videoDuration)}</p>
          <label>
            Chunk Duration (seconds):
            <input
              ref={inputRef}
              type="number"
              value={chunkDuration}
              onChange={(e) => setChunkDuration(Number(e.target.value))}
              min="1"
              max={videoDuration}
            />
          </label>
          <div className="popup-buttons">
            <button onClick={handleCutSubmit}>Cut</button>
            <button onClick={() => setShowCutPopup(false)}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length >= 4) {
      try {
        const response = await axios.get(`/api/search-clients?query=${encodeURIComponent(query)}`);
        setClientSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching client suggestions:', error);
      }
    } else {
      setClientSuggestions([]);
    }
  };

  const handleClientSelect = (client) => {
    setClientSearch(client);
    setClientSuggestions([]);
    // Use CONTENT_ prefix for the main content area
    const contentPath = `${client}/CONTENT_${client}`;
    setCurrentPath(contentPath);
    fetchDirectories(contentPath);
  };

  const SearchBar = () => {
    const inputRef = React.useRef(null);

    React.useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    return (
      <div className="search-bar">
        <input
          ref={inputRef}
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search for a client..."
        />
        {clientSuggestions.length > 0 && (
          <ul className="client-suggestions">
            {clientSuggestions.map((client, index) => (
              <li key={index} onClick={() => handleClientSelect(client)}>
                {client}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  // Updated function for directory search
  const handleDirectorySearch = (e) => {
    setDirectorySearch(e.target.value);
  };

  // Filter directories based on search
  const filteredDirectories = directories.filter(dir => 
    dir.toLowerCase().includes(directorySearch.toLowerCase())
  );

  // Focus the directory search input only on mount
  useEffect(() => {
    if (directorySearchRef.current) {
      directorySearchRef.current.focus();
    }
  }, []);  // Empty dependency array to focus only on mount

  const isMediaFile = (filename) => {
    return mediaExtensions.some(ext => filename.toLowerCase().endsWith(ext));
  };

  const filteredMediaItems = mediaItems.filter(item => isMediaFile(item.name));

  const handleDeleteMedia = async () => {
    const itemsToDelete = isSelectManyMode ? selectedMediaItems : [selectedMedia];
    
    if (itemsToDelete.length === 0) {
      alert('Please select at least one media item to delete');
      return;
    }

    try {
      setIsEditing(true);
      setEditProgress(0);

      const filePaths = itemsToDelete.map(item => item.path);
      const response = await axios.post('/api/delete', { filePaths });

      console.log(response.data);

      // Handle the response
      response.data.forEach((result, index) => {
        if (result.status === 'success') {
          console.log(`Deleted ${result.filePath} successfully`);
        } else {
          console.error(`Failed to delete ${result.filePath}: ${result.message}`);
          setError(`Failed to delete ${itemsToDelete[index].name}: ${result.message}`);
        }
        setEditProgress((index + 1) / itemsToDelete.length * 100);
      });

      await fetchMediaItems();
      setSelectedMedia(null);
      setSelectedMediaItems([]);
      setIsSelectManyMode(false);
    } catch (error) {
      console.error('Failed to delete media:', error);
      setError(`Failed to delete media: ${error.response?.data?.error || error.message}`);
    } finally {
      setTimeout(() => {
        setIsEditing(false);
        setEditProgress(0);
      }, 500);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Shift') setIsShiftPressed(true);
    };
    const handleKeyUp = (e) => {
      if (e.key === 'Shift') setIsShiftPressed(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleMediaItemClick = (item) => {
    if (isSelectManyMode) {
      setSelectedMediaItems(prev => {
        const isSelected = prev.some(i => i.path === item.path);
        if (isSelected) {
          return prev.filter(i => i.path !== item.path);
        } else {
          return [...prev, item];
        }
      });
    } else {
      setSelectedMedia(item);
      setSelectedMediaItems([item]);
    }
  };

  useEffect(() => {
    if (clientSearchRef.current && searchQuery) {
      clientSearchRef.current.blur();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (clientSearchRef.current) {
      clientSearchRef.current.blur();
    }
  }, []);

  const handleClientSearch = async (e) => {
    const query = e.target.value;
    setClientSearch(query);
    
    // Only perform search if the query is different from the current selection
    if (query.length >= 4 && query !== clientSearch) {
      try {
        // Update the endpoint to match your backend route
        const response = await axios.get(`/api/search-clients?query=${encodeURIComponent(query)}`);
        setClientSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching client suggestions:', error);
        setClientSuggestions([]);
      }
    } else {
      setClientSuggestions([]);
    }
  };

  const handleSelectManyToggle = () => {
    setIsSelectManyMode(!isSelectManyMode);
    if (isSelectManyMode) {
      setSelectedMediaItems([]);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Media Editing Suite</h1>
      </header>
      <main className="App-main">
        <section className="controls">
          <div className="search-controls-row">
            <div className="client-search-container">
              <input
                type="text"
                placeholder="Search for clients..."
                value={clientSearch}
                onChange={handleClientSearch}
                className="client-search-input"
              />
              {clientSuggestions.length > 0 && (
                <ul className="client-suggestions">
                  {clientSuggestions.map((client, index) => (
                    <li key={index} onClick={() => handleClientSelect(client)}>
                      {client}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="directory-controls">
              <input
                ref={directorySearchRef}
                type="text"
                placeholder="Search directories..."
                value={directorySearch}
                onChange={handleDirectorySearch}
                className="directory-search-input"
                onKeyDown={(e) => e.stopPropagation()}
              />
              <span className="current-path" title={currentPath}>
                {formatPath(currentPath) || 'Select a directory'}
              </span>
            </div>
          </div>
          <div className="directory-list">
            {filteredDirectories.map((dir, index) => (
              <button key={index} onClick={() => handleDirectoryClick(dir)}>{dir}</button>
            ))}
          </div>
        </section>
        {totalDuration > 0 && (
          <div className="duration-counter">
            Total Duration: {formatDuration(totalDuration)}
          </div>
        )}
        {error && <p className="error">{error}</p>}
        <section className="action-buttons">
          <button 
            className="back-button" 
            onClick={handleBackClick} 
            disabled={currentPath === ''}
          >
            Back
          </button>
          <button 
            onClick={fetchMediaItems} 
            className="fetch-btn" 
            disabled={isEditing}
          >
            Fetch Media
          </button>
          <button onClick={handleSelectManyToggle} className={`select-many-btn ${isSelectManyMode ? 'active' : ''}`}>
            {isSelectManyMode ? 'Cancel Select Many' : 'Select Many'}
          </button>
          <button 
            onClick={handleDeleteMedia} 
            className="action-btn" 
            disabled={isEditing || (isSelectManyMode ? selectedMediaItems.length === 0 : !selectedMedia)}
          >
            Delete
          </button>
          {['Compress', 'Convert', 'Trim', 'Add Captions', 'Rename', 'Cut'].map((action) => (
            <button 
              key={action} 
              onClick={() => handleEditAction(action.toLowerCase().replace(' ', ''))}
              className="action-btn"
              disabled={isEditing}
            >
              {action}
            </button>
          ))}
        </section>
        {renderProgressBar()}
        <div className="page-layout">
          <div className="content-area">
            <div className="media-container">
              <MediaViewer selectedMedia={selectedMedia} />
              <MediaGallery 
                mediaItems={mediaItems} 
                onSelect={handleMediaItemClick}
                onDurationChange={setTotalDuration}
              />
              <ClientInstructions />
            </div>
          </div>
          <FolderManagement 
            currentPath={currentPath}
            onFileMove={fetchMediaItems}
            onRefresh={fetchDirectories}
            onMediaSelect={setSelectedMedia}
          />
        </div>
        {showTrimPopup && <TrimPopup />}
        {showRenamePopup && <RenamePopup />}
        {showCutPopup && <CutPopup />}
      </main>
    </div>
  );
}

export default App;
